.accordionRoot {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.accordionItem {
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
}

.accordionHeader {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 16px 12px;
    cursor: pointer;
}

.accordionTrigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;


    background: none;
    border: none;
    outline: none;

    font-weight: 500;
    font-size: 16px;
    color: #000;
}

.chevron {
    transition: transform 0.3s ease;
    transform: rotate(-90deg);
}

[data-state="open"] .chevron {
    transform: rotate(0);
}

.accordionContent {
    padding: 0 15px 10px 20px;
    background: #ffffff;
}

.topicItemWrapper {
    font-size: 14px;
    margin-bottom: 4px;
    border-radius: 16px;
    cursor: pointer;
}
.topicItemWrapper:hover{
    background: #f8f9fa;
}
.topicItemWrapper.active{
    background-color: #EEEFF0;
}
.topicItemWrapper svg{
    margin-right: 8px;
}
.topicItemLink{
    display: flex;
    align-items: center;
    color: #000;
    padding: 8px;
}


.tableOfContent{
    display: flex;
    align-items: center;
    margin: 24px 0 16px 0;
    font-weight: 700;
    font-size: 20px;
    color: #666d80;
}
.tableOfContent svg{
    margin-right: 8px;
}