.popup__layout_bottom{
	  z-index: 3;
    width: 100%;
	  background: white;
    height: calc( 100% - 64px );
    overflow-y: hidden;
}
.popup__layout_left{
    z-index: 3;
    background: white;
    max-width: 360px;
    overflow-y: hidden;
}
.header_container{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.header_block{
    width: 100%;
    padding: 24px 24px 16px;
    box-sizing: border-box;
}
.title_row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    height: 48px;
}
.title{
    font-weight: 700;
    font-size: 24px;
    color: #17181c;
}
.subtitle{
    font-weight: 500;
    font-size: 16px;
    color: #666d80;
    margin-bottom: 4px;
}
.createNewChat{
    background: #f1f2f4;
    border-radius: 16px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.chats_block{
    padding: 0 24px 16px;
    box-sizing: border-box;
}



.message{
    color: #000;
    font-size: 16px;
    font-weight: 500;
    padding: 20px 0 30px;
}
.btn_return{
    padding: 10px 50px;
    border-radius: 16px;
    border: 1px solid #0060B7;
    font-weight: 600;
    color: #0060B7;
    margin-bottom: 30px;
}

.test{
    position: absolute;
    bottom: 0;
    height: 75vh;
    z-index: 3;
	background: white;
}


/* search */
.title_create{
    width: calc( 100% - 48px );
    font-weight: 700;
    font-size: 24px;
    color: #17181c;
    padding-right: 48px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
}

