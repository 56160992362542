.popup__layout_bottom{
	z-index: 3;
    max-width: 500px;
	background: white;
	padding: 25px;
}
.popup__layout_right{
	z-index: 3;
	background: white;
	max-width: 320px;
    padding: 25px;
}
.header_container{
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
}
.header_title{
    display: flex;
    font-weight: 700;
    font-size: 24px;
    color: #202020;
    align-items: center;
}

.libraryIcon{
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 8px;
    margin-right: 8px;
}
.libraryIcon path{
    fill: #202020;
    fill-opacity: 1;
}

.categoryBlock{
    margin-bottom: 32px;
}

.categoryTitle{
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    color: #000;
    margin: 0 0 8px 12px;
}

.subject_block{
    display: flex;
    margin-bottom: 8px;
}
.subject_item{
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    background: #fff;
    border-radius: 16px;
    font-weight: 600;
    font-size: 16px;
    color: #000;
    width: 100%;
    display: flex;
    align-items: center;
}
.subject_item svg{
    width: 64px;
    height: 64px;
    margin-right: 16px;
}