.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(40, 40, 40, 0.8); /* Slightly transparent black background */
    opacity: 0; /* Initially hidden */
    pointer-events: none; /* Prevent interaction when hidden */
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease-in-out; /* Smooth fade */
  }
  
  :global(.modal--active) .wrapper {
    opacity: 1;
    pointer-events: all; /* Enable interaction when active */
  }
  
  /* .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
  } */
  
  .popup__layout {
    position: relative;
    z-index: 10;
    background: white;
    width: 100%;
    height: 100%;
    overflow: auto;
    border-radius: 0; /* Full screen, no rounded corners */
    box-shadow: none; /* Remove shadows for full screen effect */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }
  
  /* .popup_layout__container {
    width: 100%;
    height: 100%;
  }
  
  .popup_layout__inner {
    width: 100%;
    height: 100%;
  }
   */