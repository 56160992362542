.row{
    display: flex;
    width: 100%;
}
.left{
    width: 320px;
    transition: width 0.3s ease, transform 0.3s ease; /* Smooth collapse and slide effect */
    border-radius: 0 32px 32px 0;
    overflow: hidden; /* Prevent content overflow */
    padding: 32px 12px;
    box-sizing: border-box;
    background: #fff;
    min-height: 100vh;
}
.right{
    /* width: calc( 100% - 330px); */
    flex-grow: 1; /* Take up the remaining space */
    transition: flex-grow 0.3s ease; /* Smoothly adjust with div1 */
    margin: 0 32px;
}

.isCollapsed .left {
    width: 0; /* Shrink width */
    transform: translateX(-100%); /* Slide content left */
    padding: 0;
    margin-right: 0;
}

.isCollapsed .right {
    flex-grow: 10;
}