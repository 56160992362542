.chatListContainer {
    padding: 0 24px 16px;
    /* box-sizing: border-box; */
  }
  
  .header_container{
    padding: 0 0 16px;
  }
  .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }
  
  .title {
    font-weight: 700;
    font-size: 24px;
    color: #17181c;
  }
  
  .btn_newChat{
    background: #ecf1fe;
    border-radius: 16px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn_closeNewChat{
    border-radius: 16px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .chatList {
    list-style: none;
    padding: 0;
    margin: 0;
  }