/* Disable vertical scroll globally */
:global(html), :global(body) {
    overflow: hidden;
    height: 100%;
    margin: 0;
  }
  
  /* Disable text selection on the whole page */
  * {
    user-select: none;
  }
  
  /* Your provided styles */
  .page{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px 30px 0;
      height: 100%;
      box-sizing: border-box;
  }
  .title{
      font-weight: 700;
      font-size: 35px;
      color: #3072e3;
      margin-bottom: 30px;
      height: 50px;
  }
  .block{
      display: flex;
      flex-direction: column;
      height: calc( 100% - 186px);
      align-items: center;
      width: 100%;
      box-sizing: border-box;
  }
  
  /* Container for each image to enforce 50% height and 100% width */
  .imgContainer {
      height: 50%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 32px;
  }
  
  /* Image styling to adjust within container while keeping aspect ratio */
  .img{
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      border-radius: 22px;
      cursor: pointer;
      box-shadow: 0 0 15px 0 rgba(34, 114, 252, 0.5);
      background: #fff;
  }
  
  /* Highlight styles applied directly to the image */
  .img.correct {
      box-shadow: 0 0 21px 0 rgba(25, 217, 73, 0.75);
  }
  .img.wrong {
      box-shadow: 0 0 21px 0 rgba(222, 30, 84, 0.75);
  }
  
  /* Modal Styles */
  .modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .modalContent {
      background: #fff;
      padding: 20px;
      text-align: center;
      border-radius: 10px;
  }
  .input {
      padding: 10px;
      margin: 10px 0;
      width: 80%;
      font-size: 16px;
      outline: none;
      border-radius: 16px;
      border: 1px solid #eee;
  }
  .button {
      background: #3072e3;
      color: #fff;
      padding: 10px 20px;
      border: none;
      cursor: pointer;
      border-radius: 5px;
      font-size: 16px;
  }
  
  /* Timer display styling */
  .timer {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 10px;
  }
  
  /* Landing Page Styles */
  .landing {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;  /* Updated: use 100% instead of 100vh */
      overflow: hidden;  /* Lock vertical scroll on the landing page */
  }
  .landingTitle {
      font-size: 60px;
      font-weight: 700;
      color: #3072e3;
      margin-bottom: 40px;
  }
  .startButton {
      background: #3072e3;
      color: #fff;
      padding: 15px 30px;
      text-decoration: none;
      border-radius: 8px;
      font-size: 24px;
  }
  
  /* Exit (X) Button for Quiz */
  .exitButton {
      position: absolute;
      top: 20px;
      right: 20px;
      background: transparent;
      border: none;
      font-size: 24px;
      cursor: pointer;
      color: #3072e3;
  }
  
  /* Cancel Button for Modal */
  .cancelButton {
      background: #eee;
      color: #333;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      font-size: 16px;
      cursor: pointer;
      margin-right: 10px;
  }
  
  /* Finish Button for Results */
  .finishButton {
      background: #3072e3;
      color: #fff;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      font-size: 16px;
      cursor: pointer;
      margin-top: 20px;
  }