.wrapper{
    width: 100%;
}

/*  */
.header{
    display: flex;
    box-sizing: border-box;
    border-radius: 24px;
    padding: 24px 32px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
    background: rgba(248, 249, 250, 0.15);
    margin-bottom: 16px;
}
/*  */
.left{
    width: 50%;
}
.title{
    font-weight: 700;
    font-size: 24px;
    color: #fff;
}
.btnStart{
    font-weight: 600;
    font-size: 16px;
    color: #000;
    border-radius: 16px;
    padding: 8px 16px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
    background: #fff;
    margin-top: 24px;
}
/*  */
.right{
    width: 50%;
    border-radius: 16px;
    padding: 16px 32px;
    background: rgba(255, 255, 255, 0.15);
}
.row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}
.progressTitle{
    font-weight: 700;
    font-size: 20px;
    color: #fff;
}
.btnProgress{
    background: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    padding: 8px 16px;
    font-weight: 300;
    font-size: 16px;
    color: #fff;
    display: flex;
    align-items: center;
}
.arrowRight{
    margin-left: 8px;
    transform: rotate(180deg);
}
/*  */
.progress_row{
    display: flex;
    justify-content: center;
    align-items: center;
}
.progress_row svg{
    margin-right: 8px;
}
.block_1, .block_2, .block_3, .block_4{
    border-radius: 15px;
    padding: 8px 16px;
    background: rgba(255, 255, 255, 0.25);
    box-sizing: border-box;
    font-weight: 400;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
}
.block_1{
    width: 19%;
}
.block_2{
    width: 19%;
}
.block_3{
    width: 19%;
}
.block_4{
    width: 39%;
}
.progress_value{
    font-weight: 700;
    font-size: 20px;
    color: #fff;
    margin-top: 8px;
    display: flex;
    justify-content: center;
}
/* Markdown */
.markdown{
    background: #f8f9fa;
    border-radius: 32px;
    padding: 32px 48px;
    margin-bottom: 16px;
}
.markdown h2{
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: #323232;
    margin-bottom: 16px;
}
.markdown p{
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    color: #7c7d7d;
    margin-bottom: 16px;
}
.markdown ul li{
    list-style: disc;
}
.markdown ul li,
.markdown ol li{
    margin: 0 0 16px 24px;
}
.markdown .example{
    border: 1px solid #f2f2f2;
    border-radius: 24px;
    padding: 12px 24px;
    background: #fff;
    margin: 0;
}
.markdown .example p{
    font-family: var(--font-family);
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    color: #808080;
}
.markdown .example p:not(:first-child){
    margin: 0 0 0 16px;
}

.markdown ul li ul li{
    list-style: none;
}
.markdown ul li .exampleHeading {
    margin-left: 0; /* No indentation for "Example:" */
    font-weight: bold;
}

.markdown ul li .indented {
    margin-left: 16px; /* Indent for nested sentences */
}