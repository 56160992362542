.breadcrumbs{
    display: flex;
    justify-content: space-between;
    padding: 68px 0 16px;
}
.left{
    display: flex;
    width: 80%;
}
.link{
    border-radius: 8px;
    padding: 16px;
    height: 40px;
    background: rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.5);
}
.link svg{
    margin-right: 8px;
}
.right{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 20%;
}
.wrapper{
    border-radius: 8px;
    padding: 8px;
    width: 40px;
    height: 40px;
    background: rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
}
.wrapper:not(:last-child){
    margin-right: 16px;
}