.popup__layout_start{
    width: 100%;
    height: 100%;
    display: flex;
    /* align-items: center; */
    background: linear-gradient(180deg, #3d65f5 0%, #87c7ff 42.56%, #fff 100%);
    padding: 16px 24px;
    box-sizing: border-box;
    justify-content: center;
}
.frame_start{
    width: 95%;
    max-width: 1200px;
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
}
/* HEADER */
.header_main{
    display: flex;
    justify-content: space-between;
    width: 95%;
}
.left{
    display: flex;
    align-items: center;
}
.transparent_button{ 
    cursor: pointer;
    background: rgba(255, 255, 255, 0.25);
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: #fff;
    font-weight: 800;
    font-size: 20px;
}
.transparent_button img{
    width: 24px;
    height: 24px;
}
.header_title{
    margin-left: 16px;
    display: flex;
    flex-direction: column;
}
.topicName{
    font-weight: 700;
    font-size: 24px;
    color: #fff;
}
.chapterName{
    font-weight: 500;
    font-size: 16px;
    color: #fff;
}

/* BODY */
.body{
    box-shadow: 0 1px 11px 0 #3636d7;
    background: #fff;
    border-radius: 20px;
    padding: 30px 60px;
    width: 95%;
    max-width: 800px;
    margin: 20px auto 30px;
}
.setting_block{
    margin-bottom: 52px;
}
.settings_header{
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}
.noTimeLimit{
    display: flex;
    justify-content: center;
}
/* FOOTER */
.footer{
    display: flex;
    justify-content: center;
}
.btn_start{
    background: #3636d7;
    padding: 12px 68px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    color: #fff;
    border-radius: 16px;
}


/* CONTINUE */
.popup__layout_continue{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #3d65f5 0%, #87c7ff 42.56%, #fff 100%);
    padding: 16px 24px;
    box-sizing: border-box;
}
.frame_continue{
    box-shadow: 0 -3px 9px 0 rgba(0, 0, 0, 0.25);
    background: #fff;
    padding: 32px 24px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
    max-width: 800px;
    margin-bottom: 32px;
}
/*  */
.header{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 28px;
}
.header_text_grey{
    font-weight: 700;
    font-size: 28px;
    color: rgba(0, 0, 0, 0.25);
}
.header_text_blue{
    font-weight: 700;
    font-size: 32px;
    color: #3636d7;
}

/*  */
.stats{
    display: flex;
    justify-content: center;
    width: 80%;
    box-shadow: 0 0 11px 0 rgba(54, 54, 215, 0.27);
    border-radius: 20px;
    padding: 20px 30px;
    box-sizing: border-box;
    margin-bottom: 28px;
}
.stats_block_left{
    margin-right: 60px;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 48px;
}
/*  */
.stats_block_right{
    display: flex;
    flex-direction: column;
    width: 80%;
}
.stats_title{
    margin: 0 auto;
    font-weight: 700;
    font-size: 20px;
    color: rgba(124, 124, 241, 0.4);
    margin-bottom: 28px;
}
.row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
}
.stats_left{
    display: flex;
    align-items: center;
}
.stats_icon{
    margin-right: 16px;
    width: 24px;
    height: 24px;
}
.stats_label{
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #bebebe;
}
.stats_right{
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    color: #3636d7;
}
/*  */
.info{
    display: flex;
    margin-bottom: 28px;
}
.info_text{
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: rgba(0, 0, 0, 0.8);
    margin: 0 12px;
}
/*  */
.buttons{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.btn_continue{
    border-radius: 16px;
    padding: 12px 36px;
    background: #3636d7;
    font-weight: 500;
    font-size: 16px;
    color: #fff;
    margin-bottom: 32px;
}
.btn_goBack{
    border-radius: 16px;
    padding: 12px 36px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
    background: #fff;
    font-weight: 500;
    font-size: 14px;
    color: #3636d7;
}