.hidden_radio {
    display: none; /* Hide the default radio button */
}
.radio_options{
    display: flex;
    justify-content: center;
}
.radio_option:not(:last-child){
    margin-right: 32px;
}
.radio_option{
    display: flex;
    border-radius: 16px;
    padding: 16px 48px;
    background: #fafafa;
    cursor: pointer;
    color: #707070;
}
.radio_option.selected {
    box-shadow: 0 0 9px 0 rgba(54, 54, 215, 0.25);
    color: #3636d7;
  }