.user_chat{
    border-radius: 20px;
    padding: 12px 16px;
    background: #fbfbfb;
    box-sizing: border-box;
    display: flex;
}

.chatAvatar{
    width: 56px;
    display: flex;
    flex-direction: column;
    margin-right: 12px;
}
.initials{
    border-radius: 28px;
    padding: 10px;
    margin-bottom: 2px;
    width: 56px;
    height: 56px;
    background: #57b5e7;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 700;
    font-size: 20px;
    color: #fff;
}
.status{
    display: flex;
    align-items: center;
}
.status svg{
    margin-right: 4px;
}
.online{
    font-weight: 500;
    font-size: 12px;
    color: #3b9c30;
}
.offline{
    font-weight: 500;
    font-size: 12px;
    color: #dc2d16;
}


/* second div */
.directOrGroupName_block{
    display: flex;
    align-items: center;
}
.tribeImage{
    width: 20px;
    height: 20px;
    margin-right: 8px;
}
.senderName{
    font-weight: 800;
    font-size: 16px;
    color: #17181c;
}

.lastMessage{
    font-weight: 500;
    font-size: 16px;
    color: #666d80;
}