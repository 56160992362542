.popup__layout{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, #3d65f5 0%, #87c7ff 42.56%, #fff 100%);
    padding: 16px 24px;
    box-sizing: border-box;
}
/* CONTAINER 1 */
.header_container_1{
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}
.container_1_right{
    display: flex;
}
.btn_reset{
    border-radius: 16px;
    padding: 8px 20px;
    background: rgba(255, 255, 255, 0.1);
    font-weight: 500;
    font-size: 16px;
    color: #fff;
    margin-right: 8px;
    display: flex;
    align-items: center;
}
.btn_reset img{
    margin-right: 4px;
}

.btn_exit{
    border-radius: 16px;
    padding: 8px 20px;
    background: #fff;
    font-weight: 500;
    font-size: 16px;
    color: #0e0e52;
    display: flex;
    align-items: center;
}
.btn_exit img{
    margin-right: 4px;
}
/* CONTAINER 2 */
.header_container_2{
    border-radius: 16px;
    padding: 4px 16px;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}
.container_2_left,
.container_2_middle,
.container_2_right{
    display: flex;
    align-items: center;
    width: 33%;
}
.container_2_left{
    justify-content: flex-start;
}
.container_2_middle{
    justify-content: center;
}
.container_2_right{
    justify-content: flex-end;
}
.questionsLeftTitle{
    font-weight: 400;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.5);
    margin-right: 4px;
}
.numberOfQuestionsLeft{
    font-weight: 600;
    font-size: 20px;
    color: #fff;
}
.container_2_middle img{
    margin-right: 4px;
}
.timer{
    font-weight: 700;
    font-size: 20px;
    color: #fff;
}
.stats_wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
}
.stats_wrapper:not(:last-child){
    margin-right: 24px;
}
.stats_wrapper img{
    margin-right: 4px;
}


/* MAIN CONTENT */
.main_content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 50%;
}
.exerciseTask{
    margin: 24px 0;
    font-weight: 600;
    font-size: 32px;
    line-height: 150%;
    color: rgba(255, 255, 255, 0.5);
}
.exerciseExpression{
    border-radius: 32px;
    padding: 32px 110px;
    background: #f8f9fa;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 64px;
    color: #323232;
    box-shadow: 0 0px 8px 0 #3b5fe3;
}
.exerciseExpression.correct{
    background: green;
}
.exerciseExpression.incorrect{
    background: red;
}
/* BOTTOM */
.bottom{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 24px 24px 0 0;
    padding: 16px 16px 24px 16px;
    width: 100%;
    height: 120px;
    box-shadow: 0 -4px 8px 0 rgba(59, 95, 227, 0.05);
    background: linear-gradient(180deg, rgba(62, 102, 245, 0.25) 0%, rgba(62, 102, 245, 0.25) 100%);
    box-sizing: border-box;
}
.problem_field{
    border: 2px solid #fff;
    border-radius: 24px;
    padding: 16px 24px;
    backdrop-filter: blur(8px);
    background: rgba(255, 255, 255, 0.25);
    font-weight: 600;
    font-size: 52px;
    color: #fff;
    width: 20%;
    height: 100%;
    box-sizing: border-box;
    text-align: center;
    outline: none;
}
.problem_field::placeholder{
    color: rgba(255, 255, 255, 0.5);
}
.btn_check{
    border-radius: 24px;
    padding: 16px 20px;
    background: #fff;
    font-weight: 500;
    font-size: 16px;
    color: #000;
    height: 70%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn_check img{
    margin-right: 4px;
}