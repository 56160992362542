.switchContainer {
    display: flex;
    align-items: center;
  }
  
  .switchLabel {
    font-size: 16px;
    font-weight: 500;
    color: #333;
  }
  
  .switch {
    width: 50px;
    height: 28px;
    background-color: #ccc;
    border-radius: 50px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .switchThumb {
    width: 24px;
    height: 24px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
    transition: transform 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .checked {
    /* background-color: #4cd964; iOS green */
    background-color: #3636d7; /* iOS green */
  }
  
  .checked .switchThumb {
    transform: translateX(22px);
  }