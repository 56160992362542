:global(.modal.modal--active .modal__container) {
	overflow: hidden;
}

/* :global(.modal--active) .popup__layout{
	top: 0;
} */
:global(.modal--active) .main_container{
	/* transition: opacity 0.3s cubic-bezier(0.77,0.2,0.05,1.0); */
    /* opacity: 1; */
}
.popup_layout__container{
	width: 100%;
	height: 100%;
}
.closeDragBtn_wrapper{
	/* position: absolute;
	top: 0;
	left: 0;
	right: 0; */
	position: relative;
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 10px 0;
	height: 28px;
}
.closeDragBtn{
	height: 8px;
	width: 48px;
	background-color: #686868;
	cursor: grab;
	touch-action: none;
	border-radius: 16px;
}
.closeDragBtn:active{
	cursor: grabbing;
}
.popup_layout__inner{
	width: 100%;
	height: calc(100% - 28px)
}
.wrapper{
	/* transition: opacity 0.3s cubic-bezier(0.77,0.2,0.05,1.0); */
	width: 100%;
	height: 100%;
    /* transition: 250ms ease; */
    /* opacity: 1; */
}
.backdrop{
	display: none;
}
.main_container{
	position: absolute;
	left: 0; 
    right: 0; 
    margin: 0 auto;
    bottom: 0;
	overflow: overlay;
	overscroll-behavior: contain;
	cursor: initial;
	width: 100%;
	border-radius: 30px 30px 0 0;
	box-sizing: border-box;
}
:global(.modal--active) .backdrop{
    display: flex;
    width: 100%;
	height: 100%;
	z-index: 2;
	/* background-color: #282828bc; */
	top: 0;
	position: fixed;
}


