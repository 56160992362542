/* search */
.searchContainer{
    border: 1px solid #f1f1f1;
    border-radius: 16px;
    display: flex;
    align-items: center;
    height: 48px;
    margin-bottom: 16px;
}
.searchIconLabel{
    height: 100%;
    padding: 8px 8px 8px 16px;
    display: flex;
    align-items: center;
}
.searchInput{
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #aaaebb;
    outline: none;
    border: none;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border-radius: 16px;
}
/* end of search */