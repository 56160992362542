/* Outer container for conversation */
.conversationContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #fff;
    box-sizing: border-box;
    position: relative;
  }
  
  /* Header container at top */
  .header {
    flex-shrink: 0;
    height: 64px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    background: #fff;
    box-shadow: -3px 4px 4px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    z-index: 1;
  }
  
  /* Chat name styling in header */
  .chatName {
    font-weight: 800;
    font-size: 16px;
    color: #17181c;
    margin-left: 16px; /* adds spacing from the back button */
  }
  
  /* Back button styling */
  .backButton {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    padding: 8px;
  }
  
  /* Messages container: occupies available space and scrollable */
  .messagesContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; /* aligns messages to the bottom */
    overflow-y: auto;
    padding: 0 16px 16px; /* side and bottom padding */
    box-sizing: border-box;
    position: relative;
  }
  
  /* Individual message bubble for messages sent by current user */
  .myMessage {
    align-self: flex-end;
    background: #dcf8c6;
    border-radius: 12px;
    padding: 8px 12px;
    margin: 4px 0;
    max-width: 80%;
  }
  
  /* Individual message bubble for messages from other participant */
  .otherMessage{
    align-self: flex-start;
    background: #f1f0f0;
    border-radius: 12px;
    padding: 8px 12px;
    margin: 4px 0;
    max-width: 80%;
  }
  
  /* Optional styling for message content */
  .messageContent {
    display: flex;
    flex-direction: column;
  }
  
  /* Timestamp styling for messages */
  .messageTimestamp {
    font-size: 12px;
    color: #999;
    align-self: flex-end;
    margin-top: 4px;
  }
  
  /* Footer input area remains fixed at the bottom */
  .inputArea {
    flex-shrink: 0;
    height: 58px;
    display: flex;
    align-items: center;
    padding: 8px;
    background: #f9f9f9;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
  }
  
  /* Input field styling */
  .input {
    height: 100%;
    outline: none;
    border: none;
    width: 100%;
    border-radius: 16px;
    margin-right: 8px;
    padding: 8px 16px;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 16px;
    line-height: 101%;
    color: #666668;
  }
  
  /* Send button styling */
  .sendButton {
    padding: 8px 8px 8px 2px;
    background: #007bff;
    border: none;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* Scroll arrow button styling */
  .scrollArrowButton {
    position: absolute;
    bottom: 80px;
    right: 30px;
    z-index: 10;
    background-color: blue;
    padding: 10px 12px;
    border-radius: 30px;
  }
  
  /* Badge styling (if used) */
  .badge {
    /* Optional additional styling for badge inside the button */
  }